<template>
  <v-app-bar app clipped-left clipped-right color="primary" dark>
    <v-toolbar-title class="align-center d-flex">
      <span class="logo-text" :class="`${showLogo ? '' : 'hidelogo'}`">
        <img style="margin-top: 10px; height: 30px; width: auto" src="../../../assets/images/logo_per.png"/>
      </span>
      <span class="ml-2 d-none d-sm-block" style="font-weight: 100; font-size: 14pt">VOLTADO</span>
      <small class="ml-2 d-none d-sm-block">v3.0509</small>
    </v-toolbar-title>
    <div @click="showhideLogo">
      <v-app-bar-nav-icon @click="$vuetify.breakpoint.mdAndDown ? setSidebarDrawer(!Sidebar_drawer) : $emit('input', !value)"/>
    </div>
    <v-spacer/>
    <v-btn @click="setRightSidebar" dark icon class="mr-2">
      <v-badge v-if="$store.getters.getNotifications.length > 0" color="red" dot>
        <v-icon small>mdi-bell</v-icon>
      </v-badge>
      <v-icon v-else small>mdi-bell</v-icon>
    </v-btn>
    <small class="mr-2 d-none d-sm-block">{{ $store.getters.profileName }}</small>
    <v-menu bottom left offset-y origin="top right" transition="scale-transition">
      <template v-slot:activator="{ on }">
        <v-btn icon v-on="on" class="mr-1">
          <v-avatar right color="primary" size="30">
            <v-icon v-if="!$store.getters.profileImage" size="30" color="white">mdi-account-circle</v-icon>
            <v-img v-else size="30" :src="$store.getters.profileImage"/>
          </v-avatar>
        </v-btn>
      </template>
      <v-list dense>
        <v-list-item @click="$router.push('/profile')">
          <v-list-item-icon>
            <v-icon size="sm" v-text="$icons['profile']"/>
          </v-list-item-icon>
          <v-list-item-title>{{ $store.getters.translate("profile") }}</v-list-item-title>
        </v-list-item>
        <v-list-item @click="logout">
          <v-list-item-icon>
            <v-icon size="sm" v-text="'mdi-logout'"/>
          </v-list-item-icon>
          <v-list-item-title>{{ $store.getters.translate("logout") }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>
<script>
import { mapState, mapMutations } from "vuex";
import Pusher from "pusher-js";
export default {
  name: "Header",
  components: {},
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    let pusher = new Pusher(process.env.VUE_APP_PUSHER_APP_KEY, {
      cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
      encrypted: true,
    });
    Pusher.logToConsole = true;
    pusher.subscribe("notifyUser");
    pusher.bind("notify-user", (data) => {
      if (this.$store.state.user.id === data.user) {
        this.$toasted.info(this.$lodash.truncate(this.$store.getters.translate(data.payload), { length: 100 }));
        this.getNotifications();
      } else if (data.user === "all") {
        this.$toasted.info(this.$store.getters.translate(data.payload));
        this.$store.dispatch("refreshTranslations");
      }
    });
  },
  mounted() {
    this.getNotifications();
  },
  data() {
    return {
      showLogo: true,
      showSearch: false,
    };
  },
  computed: {
    ...mapState(["Sidebar_drawer"]),
  },
  methods: {
    ...mapMutations({
      setSidebarDrawer: "SET_SIDEBAR_DRAWER",
    }),
    setRightSidebar() {
      this.getNotifications();
      this.$store.dispatch(
        "setRightSidebar",
        !this.$store.getters.rightSidebarDrawer
      );
    },
    showhideLogo: function () {
      this.showLogo = !this.showLogo;
    },
    logout() {
      this.$store.dispatch("logout");
      return this.$router.push("/login");
    },
    async getNotifications() {
      this.$http
        .get(this.$store.getters.appUrl + "v2/notifications")
        .then((response) => {
          this.$store.dispatch("setNotifications", response.data);
        })
        .catch((error) => {
          if (this.$store.getters.isLoggedIn) {
            this.$toasted.error(error);
          }
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss">
.v-application #main-sidebar.theme--dark.white {
  background: #363636 !important;
}
.hidelogo {
  display: none;
}
.searchinput {
  position: absolute;
  width: 100%;
  margin: 0;
  left: 0;
  z-index: 10;
  padding: 0 15px;
}
.descpart {
  max-width: 220px;
}
</style>