import icons from './plugins/icons'
import store from './store'
export default [
    {
        icon: "mdi-desktop-mac-dashboard",
        title: "Dashboard",
        gate: { subject: 'dashboard', action: 'show' },
        to: "/"
    },
    {
        header: store.getters.translate("administration"),
        gate: { action: ['worker', 'certificate', 'training', 'project', 'note'] }
    },
    {
        icon: icons['workers'],
        gate: { subject: 'worker', action: 'show' },
        title: store.getters.translate("workers"),
        to: "/workers",
    },
    {
        icon: icons['certificates'],
        gate: { subject: 'certificate', action: 'show' },
        title: store.getters.translate("certificates"),
        to: "/certificates",
    },
    {
        icon: icons['trainings'],
        gate: { subject: 'training', action: 'show' },
        title: store.getters.translate("trainings"),
        to: "/trainings"
    },
    {
        icon: icons['projects'],
        gate: { subject: 'project', action: 'show' },
        title: store.getters.translate("projects"),
        to: "/projects",
    },
    {
        icon: icons['notes'],
        gate: { subject: 'note', action: 'show' },
        title: store.getters.translate("notes"),
        to: "/notes",
    },
    {
        header: store.getters.translate("relations_management"),
        gate: { action: ['company', 'contact'] }
    },
    {
        icon: icons['companies'],
        gate: { subject: 'company', action: 'show' },
        title: store.getters.translate("companies"),
        to: "/companies",
    },
    {
        icon: icons['contacts'],
        gate: { subject: 'contact', action: 'show' },
        title: store.getters.translate("contacts"),
        to: "/contacts",
    },
    {
        header: store.getters.translate("configuration"),
        gate: { action: ['scheme', 'template'] }
    },
    {
        icon: icons['schemes'],
        gate: { subject: 'scheme', action: 'show' },
        title: store.getters.translate("schemes"),
        to: "/schemes",
    },
    {
        icon: icons['templates'],
        gate: { subject: 'template', action: 'show' },
        title: store.getters.translate("templates"),
        to: "/templates",
    },
    {
        header: store.getters.translate("settings"),
        gate: { action: ['translation', 'field', 'tag', 'employee', 'role', 'workflow', 'setting'] }
    },
    {
        icon: icons['translations'],
        gate: { subject: 'translation', action: 'show' },
        title: store.getters.translate("translations"),
        to: "/translations",
    },
    {
        icon: icons['fields'],
        gate: { subject: 'field', action: 'show' },
        title: store.getters.translate("fields"),
        to: "/fields",
    },
    {
        icon: icons['tags'],
        gate: { subject: 'tag', action: 'show' },
        title: store.getters.translate("tags"),
        to: "/tags",
    },
    {
        icon: icons['employees'],
        gate: { subject: 'employee', action: 'show' },
        title: store.getters.translate("employees"),
        to: "/employees",
    },
    {
        icon: icons['roles'],
        gate: { subject: 'role', action: 'show' },
        title: store.getters.translate("roles"),
        to: "/roles",
    },
    {
        icon: icons['workflows'],
        gate: { subject: 'workflow', action: 'show' },
        title: store.getters.translate("workflows"),
        to: "/workflows",
    },
    {
        icon: icons['settings'],
        gate: { subject: 'settings', action: 'show' },
        title: store.getters.translate("settings"),
        to: "/settings",
    }
]