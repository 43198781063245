<template>
  <v-navigation-drawer
    :value="this.$store.getters.rightSidebarDrawer"
    @input="setRightSidebar"
    app
    :right="!$vuetify.rtl"
    clipped
    :width="$vuetify.breakpoint.mobile ? '70%' : '300px'">
    <v-list dense class="mt-0 pt-0">
      <v-list-item class="grey lighten-4">
        <v-list-item-icon left>
          <v-icon @click="$store.dispatch('setRightSidebar', false)" small>mdi-close</v-icon>
        </v-list-item-icon>
        <v-list-item-title>
          {{ $store.getters.translate("messages") }}
        </v-list-item-title>
        <v-list-item-action v-if="$store.getters.getNotifications.length != 0">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                right
                small
                :loading="loading_all"
                color="secondary"
                v-bind="attrs"
                v-on="on"
                @click.prevent="deleteAllNotifications()">
                mdi-delete-sweep
              </v-icon>
            </template>
            <span>{{ $store.getters.translate("delete_all_notifications") }}</span>
          </v-tooltip>
        </v-list-item-action>
      </v-list-item>
      <v-divider/>
      <v-list-item class="mt-2" v-for="(item, i) in $store.getters.getNotifications" :key="i">
        <v-list-item-content>
          <a v-if="item.data.local_url" @click="$router.replace('/' + item.data.local_url)"><small v-html="item.data.message"/></a>
          <small class="ma-0" v-else v-html="item.data.message"/>
          <v-list-item-subtitle>
            <small class="text--secondary">{{ $store.getters.translate("created") }} {{ moment(item.created_at).format("DD-MM-YYYY") }}</small>
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-icon right small @click.prevent="deleteNotification(item.id)">mdi-trash-can</v-icon>
        </v-list-item-action>
      </v-list-item>
      <v-list-item v-if="$store.getters.getNotifications.length == 0" class="text-center">
        <small class="text-center">{{ $store.getters.translate("no_notifications") }}</small>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import Proxyable from "vuetify/lib/mixins/proxyable";
export default {
  name: "RightSidebar",
  mixins: [Proxyable],
  data: () => ({
    right: false,
    SidebarColor: "",
    SidebarColors: [
      "white",
      "#2b2b2b",
      "rgb(44, 59, 164)",
      "rgb(96, 44, 164)",
      "rgb(151, 210, 219)",
      "rgb(77, 86, 100)",
    ],
    themecolor: "#01355F",
    themecolors: [
      "#01355F",
      "#21c1d6",
      "#fc4b6c",
      "#563dea",
      "#9C27b0",
      "#ff9800",
    ],
    loading: false,
    loading_all: false,
  }),
  computed: {
    setSidebarColor: {
      get() {
        return this.$store.state.SidebarColor;
      },
      set(val) {
        this.$store.commit("SET_SIDEBAR_COLOR", val);
      },
    },
  },
  watch: {
    themecolor(val) {
      (this.$vuetify.theme.themes[
        this.isDark ? "dark" : "light"
      ].primary = val),
        (this.$vuetify.theme.themes[
          this.isLight ? "light" : "dark"
        ].primary = val);
    },
    setSidebarColor(val) {
      this.$store.commit("SET_SIDEBAR_COLOR", val);
    },
  },
  methods: {
    setRightSidebar(event) {
      this.$store.dispatch("setRightSidebar", event);
    },
    deleteNotification(id) {
      this.loading = true;

      this.$http
        .delete(this.$store.getters.appUrl + "v2/notifications/" + id)
        .then(() => {
          this.$toasted.success(this.$store.getters.translate("success"));
          this.loading = false;
          this.getNotifications();
        })
        .catch((error) => {
          if (this.$store.getters.isLoggedIn) {
            this.$toasted.error(error);
          }
          this.loading = false;
        });
    },
    deleteAllNotifications() {
      this.loading_all = true;

      this.$http
        .post(this.$store.getters.appUrl + "v2/notifications/delete/all")
        .then(() => {
          this.$toasted.success(this.$store.getters.translate("success"));
          this.loading_all = false;
          this.getNotifications();
        })
        .catch((error) => {
          if (this.$store.getters.isLoggedIn) {
            this.$toasted.error(error);
          }
          this.loading_all = false;
        });
    },
    getNotifications() {
      this.$http
        .get(this.$store.getters.appUrl + "v2/notifications?set_read=true")
        .then((response) => {
          this.$store.dispatch("setNotifications", response.data);
        })
        .catch((error) => {
          if (this.$store.getters.isLoggedIn) {
            this.$toasted.error(error);
          }
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss">
.v-application .v-item-group .v-sidebar_active {
  border: 1px solid black !important;
}

.v-application p {
  margin-bottom: 0;
}
</style>