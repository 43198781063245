import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VNavigationDrawer,{attrs:{"value":this.$store.getters.rightSidebarDrawer,"app":"","right":!_vm.$vuetify.rtl,"clipped":"","width":_vm.$vuetify.breakpoint.mobile ? '70%' : '300px'},on:{"input":_vm.setRightSidebar}},[_c(VList,{staticClass:"mt-0 pt-0",attrs:{"dense":""}},[_c(VListItem,{staticClass:"grey lighten-4"},[_c(VListItemIcon,{attrs:{"left":""}},[_c(VIcon,{attrs:{"small":""},on:{"click":function($event){return _vm.$store.dispatch('setRightSidebar', false)}}},[_vm._v("mdi-close")])],1),_c(VListItemTitle,[_vm._v(" "+_vm._s(_vm.$store.getters.translate("messages"))+" ")]),(_vm.$store.getters.getNotifications.length != 0)?_c(VListItemAction,[_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({attrs:{"right":"","small":"","loading":_vm.loading_all,"color":"secondary"},on:{"click":function($event){$event.preventDefault();return _vm.deleteAllNotifications()}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete-sweep ")])]}}],null,false,3547246232)},[_c('span',[_vm._v(_vm._s(_vm.$store.getters.translate("delete_all_notifications")))])])],1):_vm._e()],1),_c(VDivider),_vm._l((_vm.$store.getters.getNotifications),function(item,i){return _c(VListItem,{key:i,staticClass:"mt-2"},[_c(VListItemContent,[(item.data.local_url)?_c('a',{on:{"click":function($event){return _vm.$router.replace('/' + item.data.local_url)}}},[_c('small',{domProps:{"innerHTML":_vm._s(item.data.message)}})]):_c('small',{staticClass:"ma-0",domProps:{"innerHTML":_vm._s(item.data.message)}}),_c(VListItemSubtitle,[_c('small',{staticClass:"text--secondary"},[_vm._v(_vm._s(_vm.$store.getters.translate("created"))+" "+_vm._s(_vm.moment(item.created_at).format("DD-MM-YYYY")))])])],1),_c(VListItemAction,[_c(VIcon,{attrs:{"right":"","small":""},on:{"click":function($event){$event.preventDefault();return _vm.deleteNotification(item.id)}}},[_vm._v("mdi-trash-can")])],1)],1)}),(_vm.$store.getters.getNotifications.length == 0)?_c(VListItem,{staticClass:"text-center"},[_c('small',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.$store.getters.translate("no_notifications")))])]):_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }